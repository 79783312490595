import { fetch } from 'whatwg-fetch';
import getCountryList from 'react-select-country-list';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Images, LottoBrand } from './Constant.js';

/// <summary>
/// Author : -
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '';
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : -
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : -
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

export function MapBrandsLogoImages(brandId) {
    switch (brandId) {
        case LottoBrand._TOTO:
            return { logo: Images._TOTO_LOGO, classname: 'toto-logo-white' };
        case LottoBrand._MAGNUM:
            return { logo: Images._MAGNUM_LOGO, classname: 'magnum-logo' };
        case LottoBrand._DMC:
            return { logo: Images._DAMACAI_LOGO, classname: 'dmc-lotto-logo dmc-logo-white' };
        case LottoBrand._SG_LOTTO:
            return { logo: Images._SG_LOTTO_LOGO, classname: 'sg-lotto-logo' };
        case LottoBrand._88_LOTTO:
            return { logo: Images._88_LOTTO_LOGO, classname: '88-lotto-logo' };
        case LottoBrand._STC_LOTTO:
            return { logo: Images._STC_LOTTO_LOGO, classname: 'stc-lotto-logo' };
        case LottoBrand._DA_CASH_WAN:
            return { logo: Images._DA_CASH_WAN_LOGO, classname: 'dcw-lotto-logo' };
        case LottoBrand._GD:
            return { logo: Images._GD_LOTTO_LOGO, classname: 'gd-lotto-logo' };
        case LottoBrand._NINELOTTO:
            return { logo: Images._GD_LOTTO_LOGO, classname: 'nl-lotto-logo' };
        default:
            break;
    }
}

export function formatNumber(number) {
    return number.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export function initSharer(content) {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                action: "share",
                message: content
            })
        );
    } else {
        navigator.share({ text: content });
    }
}